import styled from 'styled-components';

const ProductOwnerWrapper = styled.div`
  background: ${props => props.theme.color.tertiaryColor};
  width: 100%;
  display: flex;
  align-items: center;

  .options {
    display: flex;
    justify-content: space-evenly;
    flex-grow: 1;
    height: 60px;
    padding-top: 5px;
  }

  .option {
    color: ${props => props.contrastColor};

    &.highlight {
      color: ${props => props.theme.color.primaryColor};
      & .icon {
        background: ${props => props.theme.color.primaryColor};
        border-color: ${props => props.theme.color.primaryColor};
        color: white;
      }
    }
  }

  a {
    color: white;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    font-size: ${props => `${11*props.theme.font.primaryFont.scaling/100}px`};
    text-transform: uppercase;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    height: 35px;
    width: 35px;
    border: 2px solid ${props => props.contrastColor};
    border-radius: 50%;
  }

  @media (min-width: ${props => props.isMobileBreakpoint}px) {
    .options {
      height: 80px;
      padding-top: 0;
      align-items: center;
    }

    a {
      font-size: ${props => `${11*props.theme.font.primaryFont.scaling/100}px`};
      text-transform: none;
      &:hover {
        text-decoration: none;
      }
    }

    .option {
      height: 64px;
      width: 64px;
      background-color: ${props => props.theme.color.secondaryColor};
      border: none;
      border-radius: 50%;
      color: white;

      &.highlight {
        color: white;
        background: ${props => props.theme.color.primaryColor};

        .icon {
          border: none;
          background: none;
        }
      }
    }
    .icon {
      border: none;
      font-size: 37px;
    }
  }
`;

export default ProductOwnerWrapper;
