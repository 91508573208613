import React, { useState, useEffect, useRef, useContext } from 'react';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import TitleWrapper from 'ToolboxComponents/webapp/integrations/layout/menu/product/components/title.style';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import { useHistory } from 'react-router-dom';

const Title = props => {
  const history = useHistory();
  const { product } = props;
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);
  const navRef = useRef();
  const arrowRef = useRef();
  const theme = useContext(ThemeContext).state;

  const [itemTop, setItemTop] = useState(false);

  const testItem = () => {
    const item = navRef.current.getBoundingClientRect();
    if (item.top <= 0 && isMobile === false) {
      setItemTop(true);
      navRef.current.classList.add('active');
    } else {
      setItemTop(false);
      navRef.current.classList.remove('active');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', testItem);

    return () => {
      window.removeEventListener('scroll', testItem);
    };
    // eslint-disable-next-line
  }, [isMobile]);

  return (
    <TitleWrapper
      ref={navRef}
      itemTop={itemTop}
      isMobile={isMobile}
      theme={theme}
    >
      <div className={`product-title`}>
        <div
          ref={arrowRef}
          className="back-arrow"
          onClick={() => history.goBack()}
        >
          <div className="adn adn-arrow-left" />
        </div>
        <div className={`title-wrapper`}>
          <h1 className="title">{product.title}</h1>
          <div className="city">{product.city}</div>
        </div>
      </div>
    </TitleWrapper>
  );
};

export default Title;
