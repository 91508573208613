import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const SecretContainer = styled.div`
  background: ${props =>
    props.isMobile === true ? props.tertiaryColor : 'white'};
  width: 100%;
  padding: 20px 0;

  .secret-slider-title {
    font-size: ${props => `${(26 - props.isMobile * 3)}px`};
    font-family: ${props => props.theme.font.secondaryFont.name};
    text-transform: uppercase;
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)};
    text-align: center;
    padding: 0 20px;
    margin-bottom: 40px;
  }

  //remove scrollbar
  .slide-description {
    -ms-overflow-style: none; // IE 10+
    overflow: -moz-scrollbars-none; // Firefox
  }

  .slide-description::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }

  .secret-slider-number {
    font-size: 16px;
    text-align: center;
    line-height: 1;
    margin-bottom: 20px;
  }

  .glider-dots {
    margin-top: 15px;
  }

  .magnify {
    cursor: pointer;
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .slider-link {
      display: flex;
    }
  }
`;

export default SecretContainer;
