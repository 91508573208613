import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  .title {
    display:block;
    margin: 0;
    font-family: ${props => props.theme.font.secondaryFont.name};
    // font-size: ${props => `${20*props.theme.font.secondaryFont.scaling/100}px`};
    font-size: 25px;
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
    // text-transform: uppercase;
    align-self: flex-start;
    margin-left: 20px;
  }

  .days {
    align-self: flex-start;
    list-style: none;
    padding-left: 0;
    width: 100%;
    // max-width: 220px;
    margin-top: 20px;
    font-size: ${props => `${17*props.theme.font.primaryFont.scaling/100}px`};
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }

  .schedule-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .name {
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .days {
      width: 50%;
    }
  }
`;

export default ScheduleWrapper;
