import styled from 'styled-components';

const DescriptionWrapper = styled.div`
  font-size: ${props => `${15*props.theme.font.primaryFont.scaling/100}px`};
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;

  .title {
    display: block;
    margin: 0;
    font-size: ${props => `${25*props.theme.font.secondaryFont.scaling/100}px`};
    font-family: ${props => props.theme.font.secondaryFont.name};
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
    align-self: flex-start;
    margin-left: 20px;
    margin-bottom: 12px;
  }

  .content {
    display: block;
  }
  .hide-bottom {
    display: ${props =>
      props.fullDescription === true ? 'block' : '-webkit-box !important'};
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    position: relative;

    ::after {
      content: '';
      display: ${props => (props.fullDescription === true ? 'none' : 'block')};
      height: 20px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  .read-more {
    color: ${props => props.color};
    border-bottom: 1px solid ${props => props.color};
    display: ${props => (props.fullDescription === true ? 'none' : 'inline')};
    line-height: 1;
    cursor: pointer;
    margin-top: 15px;
  }
`;

export default DescriptionWrapper;
