import styled from 'styled-components';

const ModalMediaWrapper = styled.div`

.modal {
  overflow-x: scroll !important;
  overflow: auto !important;
  z-index: 15000;
  display: block !important;
}

.modal,
.modal hr {
  background: #fff;
}

.modal hr {
  margin-left: auto;
  margin-right: auto;
  max-width: 290px;
}

@media only screen and (min-width: 768px) {
  .modal {
    background: rgba(0, 0, 0, 0.6);
  }

  .modal .modal-dialog {
    margin: 0;
    max-width: none;
    width: 100%;
  }
}

.modal .modal-content {
  border: 0;
  border-radius: 0;
  color: #4a4a4a;
}


.modal-full {
  background: #000;
  padding-right: 0 !important;
}

.modal-full .modal-header {
  border: 0;
  color: #fff;
}

.modal-full .modal-header h4 {
  color: #fff;
}

.modal-full .modal-content {
  background: #000;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: #fff;
}

.modal-full .modal-dialog {
  margin: 0 auto;
  max-width: none;
}

.modal-media {
  color: #4A4A4A;
}

.modal-media .modal-title {
  font-family: Roboto;
  color: #4A4A4A !important;
  font-size: 16px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 30px);
  text-align: left;
}

.modal-media .modal-content {
  background-color: white;
  color: #4A4A4A;
}

.modal-media .modal-content .container{
  padding: 0;
}

.modal-media .modal-header {
  background-color: #f5f5f5;
  margin: 0;
  padding: 13px !important;
}

.modal-media .close i {
  color: #b63f78;
  font-size: 18px;
  font-weight: bold;
}

.modal-media .modal-header button.close {
  display: block;
  float: right;
  position: static;
  color: #b63f78;
  font-size: 20px;
  font-weight: bold;
  width: 30px;
}

.modal-media iframe {
  border: 0;
  width: 100%;
  height: calc(100vh - 87px);
}

@media only screen and (max-width: 767px) {
  .modal-media .modal-header {
    background-color: #f5f5f5;
    margin: 0;
    padding: 13px 30px 13px 25px!important;
  }
}
`;

export default ModalMediaWrapper;

