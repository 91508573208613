import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import logoBifurcation from 'ToolboxAssets/img/macaron-bifurcations.png';
import logoRugby from 'ToolboxAssets/img/ballon.png';
import logoQT from 'ToolboxAssets/img/logo-qualite-tourisme.png';
import logoTorch from 'ToolboxAssets/img/torch.png';


const sidebarWidth = 340;

const ProductWrapper = styled.div`
  .page-gutters {
    padding: 0 20px;
  }
  .logo-qualite-tourisme{
    text-align:center;
    &:after{
      content:url(${logoQT});
      display:block;
    }
  }

  .macaron-biennale{
    &:after{
      content:'';
      display:block;
      background: url(${logoBifurcation}) no-repeat;
      width: 200px;
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      margin-left:-100px;
  }
  }
  .macaron-event{
    &:after{
      content:'';
      display:block;
      background: url(${logoTorch}) no-repeat white;
      background-size: contain;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      position: absolute;
      top: 231px;
      left: 10px;
      z-index: 10;
    }
  }

  .info {
    margin-top: 20px;
    .info {
      padding: 0 0.1rem 0 1rem;
    }

    .icon {
      font-size: 2rem;
      margin-right: 1rem;
    }
  }

  .benefits {
    margin-top: 30px;
  }

  .slider-secret-product {
    margin-top: 30px;
  }

  .secret-slider {
    padding: 0;

    .swiper-container {
      padding: 0 20px;
    }
  }

  .contact-map {
    margin-top: 30px;
  }

  .download {
    padding-left: 40px;
    padding-bottom: 20px;
    font-size: 17px;
    color: #212529;
    display: flex;
    align-items: center;
    font-weight: bold;
    .icon {
      padding-right: 5px; 
      font-size: 32px;
    }
  }

  .download:hover {
    text-decoration: none;
    span {
      color: ${props => props.theme.color.primaryColor}
    }
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    display: flex;
    flex-flow: wrap;
    width: 100%;

    .macaron-event{
      &:after{
        content:'';
        display:block;
        background: url(${logoTorch}) no-repeat white;
        background-size: contain;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
      }
    }

    .page-gutters {
      padding: 0 40px;
    }

    .header-slider {
      position: relative;
      min-width: 0;
      width: 100%;
    }
    .secret-slider {
      .swiper-container {
        padding: 0;
      }
    }

    .favorite {
      width: 100%;
    }

    .slider-secret-product .secret-product-card .slide-container {
      padding: 15px;
      &.no-title {
        padding-top: 30px;

      }
    }
  }
`;

export const FavoriteWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 0;
  right: 0;
  z-index: 2;
  @media (min-width: ${isMobileBreakpoint}px) {
    padding-right: ${sidebarWidth}px;
  }
`;

export const ProductMainWrapper = styled.div`
  flex: 1 0;
  .social-medias {
    display: flex;
    justify-content: space-evenly;
    margin: 50px 0;
    .social-media {
      align-items: center;
      color: inherit;
      display: flex;
      font-size: 20px;
      &:hover {
        text-decoration: none;
      }
      img {
        height: 30px;
        margin-right: 10px;
        width: 30px;
      }
    }
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    .secret-slider {
      margin: 0 auto;
      width: ${props =>
        props.productRef !== undefined &&
        props.productRef.current !== undefined &&
        props.productRef.current.clientWidth !== undefined &&
        props.productRef.current.clientWidth -
          sidebarWidth -
          20}px; //the final -20px is to fix a visual bug on the product page
    }
  }
`;

export const ProductSidebarWrapper = styled.div`
  flex: 0 ${sidebarWidth}px;
  align-self: flex-start;
  position: -webkit-sticky; /*Safari support*/
  position: sticky;
  top: -40px;
  padding-top: 40px;
  background-color: ${props => props.tertiaryColor};

  .contact-map h2 {
    font-size: ${props =>
      `${(20 * props.theme.font.secondaryFont.scaling) / 100}px`};
    font-weight: ${props =>
      parseInt(props.theme.font.secondaryFont.weight) + 300};
  }
`;

export default ProductWrapper;
