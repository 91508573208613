import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from "react-helmet";
import { WebappContext, AccountContext, GeolocationContext, WhiteLabelContext, LanguageContext } from "ToolboxUtils/web/context/context";
import ProductView from 'ToolboxComponents/webapp/pages/product-view/product-view';
import Loader from 'ToolboxComponents/commons/loader/loader';
import apiChuchoteurs from 'ToolboxUtils/web/api/api-chuchoteurs';
import useApiChuchoteurs from 'ToolboxUtils/web/hooks/use-api-chuchoteurs';
import useIsContentLoaded from 'ToolboxUtils/web/hooks/use-is-content-loaded';
import getMetaDescription from 'ToolboxUtils/web/get-meta-description';
import slugify from 'url-slug';

import { PAGE_LOAD_WAITING } from 'Params/globals';

const Product = props => {
  const [signIn, setSignIn] = useContext(WebappContext).usePath('modals.signIn');
  const [urlParams, setUrlParams] = useContext(WebappContext).usePath('urlParams');
  const [account, setAccount] = useContext(AccountContext).usePath();
  const [geolocation] = useContext(GeolocationContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();

  const [times] = useState(urlParams && urlParams['product']);
  const [isSecretSaved, setSecretSaved] = useState(false);
  const [errorOnSecretSave, setErrorOnSecretSave] = useState(false);
  const [pageInitTime, setPageInitTime] = useState(Date.now());
  const [isLoaderCountDownSet, setLoaderCountDown] = useState(false);
  const [isLoaderDisplayed, showLoader] = useState(false);

  if (!isLoaderCountDownSet) {
    setLoaderCountDown(true);
    setTimeout(function(){
      if (!product.data) {
        showLoader(true);
      }
    }, PAGE_LOAD_WAITING - (Date.now() - pageInitTime));
  }

  useEffect(() => {
    if (urlParams && urlParams['product']) {
      setUrlParams({...urlParams, product: undefined});
    }
    return () => {
      setLoaderCountDown(false);
      setPageInitTime(null);
    }
  }, []);

  const updateFav = id => {
    if (account.favorites.includes(id)) {
      const favorites = account.favorites.filter(favorite => favorite !== id);
      setAccount('favorites', favorites);
    } else {
      const favorites = account.favorites.concat([id]);
      setAccount('favorites', favorites);
    }
  }

  const openSignInModal = status => {
    setSignIn({opened: true, from: 'fav'});
  }

  const sendLogs = error => {
    apiChuchoteurs.post(`/logs`, {
      body: {error: {...error, type: 'front'}}
    })
  };

  const product = useApiChuchoteurs.get(`/products/${props.match.params.id}`, {
    params: {
      filter: 'webapp',
      language,
      whiteLabelId: whiteLabel.id,
      time: Math.round(new Date().getTime() / 1000),
      longitude: geolocation.coordinates.longitude,
      latitude: geolocation.coordinates.latitude
    },
    depends: geolocation && whiteLabel,
    onComplete: (results) => {
      if (results.isHttpError && results.error) {
        sendLogs(results.error);
      }
    },
    noTrack: ['time']
  });
  

  useIsContentLoaded(product.isComplete);
  return (
    <div>
      {product.isComplete ?
        <div>
          <Helmet>
            <title>{product.data.title} - {whiteLabel.name}</title>
            <meta name="description" content={getMetaDescription(product.data.teaser || product.data.description)}/>
            <link rel="canonical" href={'https://' + window.location.hostname + (whiteLabel.path ? '/' + whiteLabel.path : '') + props.page.path[language] + `/${product.data.id}/${slugify(product.data.title, {lower: true})}`} />
          </Helmet>
          <ProductView
            product={product.data}
            times={times}
            isSecretSaved={isSecretSaved}
            errorOnSecretSave={errorOnSecretSave}
            setSecretSaved={setSecretSaved}
            addFavorite={async (id) => {
              if (account && account.id) {
                try {
                  const favorite = await apiChuchoteurs.post(`/accounts/${account.id}/products/${id}/favorites`, {
                    token: localStorage.token
                  })
                  updateFav(id);
                } catch(err) {
                  apiChuchoteurs.post(`/logs`, {
                    body: {error: {...err, type: 'front'}}
                  })
                }
              } else {
                openSignInModal(true);
              }
            }}
            deleteFavorite={async (id) => {
              if (account && account.id) {
                try {
                  const favorite = await apiChuchoteurs.delete(`/accounts/${account.id}/products/${id}/favorites`, {
                    token: localStorage.token
                  })
                  updateFav(id);
                } catch(err) {
                  apiChuchoteurs.post(`/logs`, {
                    body: {error: {...err, type: 'front'}}
                  })
                }
              } else {
                openSignInModal(true);
              }
            }}
            createSecret={async (secret) => {
              try {
                const createdSecret = await apiChuchoteurs.post(`/secrets/`, {
                  body: {accountId: account.id, ...secret, whiteLabelId: whiteLabel.id, language: language},
                  token: localStorage.token
                })
                setSecretSaved(true);
              } catch (err) {
                setErrorOnSecretSave(true);
                apiChuchoteurs.post(`/logs`, {
                  body: {error: {...err, type: 'front'}}
                })
              }
            }}
          />
        </div>
        : isLoaderDisplayed
          ? <Loader />
          : <></>
      }
    </div>
  );
}

export default Product;
