import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext, AccountContext, WebappContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FavoriteWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 15px 0 0;
  color: #fff;
  border-radius: 100px;
  font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.1s;
  padding: 10px;
  background-color: white;
  color: ${props => props.theme.color.secondaryColor};
  box-shadow: 0 0 0 2px ${props => props.theme.color.secondaryColor} inset;
  // text-transform: uppercase;
  user-select: none;
  min-width: 126px;

  .text {
    display: flex;
  }

  .icon {
    font-size: 25px;
    line-height: 0.8;
    margin-right: 5px;
    position: relative;
    top: 1px;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

const Favorite = props => {
  const [active, setActive] = useState(false);
  const theme = useContext(ThemeContext).state;
  const [analytics] = useContext(WebappContext).usePath('analytics');
  const [account] = useContext(AccountContext).usePath();
  const { product } = props;

  useEffect(() => {
    if (account !== undefined) {
      account.favorites.includes(product.id) === true
        ? setActive(true)
        : setActive(false);
    }
  }, [account]);

  return (
    <FavoriteWrapper
      theme={theme}
      onClick={() => {
        switch (props.fromPage) {
          case 'listing': analytics.sendFavoriteListingInteractionsEvent(); break;
          case 'home': analytics.sendFavoriteHomeInteractionsEvent(); break;
          case 'product': analytics.sendFavoriteProductInteractionsEvent(); break;
          default: ;
        }
        props.onClick(active);
      }}
    >
      <span className={`icon adn adn-heart${active ? '' : '-outline'}`} />
      <span className={`text`}>
        <Text path={`generic.products.favorite`} />
        {active === true ? '' : '\u00a0?'}
      </span>
    </FavoriteWrapper>
  );
};

Favorite.propTypes = {
  onClick: PropTypes.func, //toggle on/off favorite props
  product: PropTypes.object, //product attached to button
};

export default Favorite;
