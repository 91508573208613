import styled from 'styled-components';

const AdvantagesWrapper = styled.div`
  padding-top: 20px;

  .title {
    font-family: ${props => props.theme.font.secondaryFont.name};
    color: ${props => props.color};
    margin-left: 20px;
    font-weight: ${props => parseInt(props.theme.font.secondaryFont.weight)+300};
    font-size: ${props => `${25*props.theme.font.secondaryFont.scaling/100}px`};
  }

  .list {
    font-size: ${props => `${15*props.theme.font.primaryFont.scaling/100}px`};
    padding-left: 10px;
    list-style: none;

    li {
      &::before {
        content: '*';
        padding-right: 4px;
      }
    }
  }
`;

export default AdvantagesWrapper;
