export default function formatPrice(price) {
  let tempPrice = price * 10000 / 10000;
  const nan = isNaN(tempPrice);
  if(nan) {
    return null;
  } else if (tempPrice !== parseInt(tempPrice, 10)) {
    tempPrice = tempPrice.toFixed(2);
    if (/./i.test(tempPrice)){
      tempPrice = tempPrice.replace(".", ",");
    }
    return tempPrice;
  } else {
    return tempPrice;
  }
}
