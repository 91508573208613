import React, { useState, useEffect, useContext } from 'react';
import formatSchedule from 'ToolboxUtils/web/formats/format-schedule';
import { LanguageContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import IconText from 'ToolboxComponents/commons/texts/icon-text/icon-text';
import formatDistance from 'ToolboxUtils/web/formats/distance';
import InfoWrapper from './info.style';
import PropTypes from 'prop-types';


function convertDuration(duration)  {
  const hours = Math.floor(duration / 60);
  if (hours === 0) {
    return `${duration}min`;
  }
  const minutes = duration % 60;
  if (minutes === 0) {
    return `${hours}h`;
  }
  return `${hours} h ${minutes}`;
}

const Info = props => {
  const { product, times } = props;
  const [language] = useContext(LanguageContext).usePath();
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [scheduleStatus, setScheduleStatus] = useState('');
  const [schedulePath, setSchedulePath] = useState(null);
  const [scheduleHour, setScheduleHour] = useState({});
  const [scheduleDate, setScheduleDate] = useState({});

  useEffect(() => {
    const schedule = formatSchedule(
      props.isEvent, {
      startTime: times ? times.startTime: product.startTime,
      endTime: times ? times.endTime: product.endTime,
      language,
    });

    setScheduleStatus(schedule.status);
    setSchedulePath(schedule.message);
    setScheduleHour(schedule.hour);
    setScheduleDate(schedule.date);
    // eslint-disable-next-line
  }, [product]);

  return (
    <InfoWrapper className="info">
      {schedulePath !== null && !(whiteLabel.config.isSelectedProductScheduleHidden && product.isSelectedProduct) && (
        <div className="info">
          <span className={`adn adn-clock-outline icon`} />
          <Text
            path={schedulePath}
            data={{ time: scheduleHour, date: scheduleDate }}
            className={`is-${scheduleStatus}`}
          />
        </div>
      )}
      {product.extra && product.extra.sanitaryPass && (
        <div className="info">
          <IconText
            text={whiteLabel.textualContent.page.product.sanitaryPass}
            icon={{ class: 'icon adn adn-tag-outline', text: '' }}
            aTag={false}
            stick={true}
            noSpaceBottom={true}
          />
        </div>
      )}
      {product.duration && (
        <div className="info">
          <IconText
            text={`${whiteLabel.textualContent.page.product.duration} ${convertDuration(product.duration)}`}
            icon={{ class: 'icon adn adn-timer-sand-empty', text: '' }}
            aTag={false}
            stick={true}
            noSpaceBottom={true}
          />
        </div>
      )}
      <div className="info">
        <IconText
          text={formatDistance(Number(product.distance))}
          icon={{ class: 'icon adn adn-directions', text: '' }}
          aTag={false}
          stick={true}
          noSpaceBottom={true}
        />
      </div>
    </InfoWrapper>
  );
};

Info.propTypes = {
  productInfo: PropTypes.object,
};

export default Info;
