import styled from 'styled-components';

const PoiWrapper = styled.div`
.poi-area {
  background-color: #f5f5f5;
  padding: 25px;
  margin-bottom:60px;
  font-family: Roboto;
}

.poi-area-know {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.3em;
  color: #b63f78;
}

.poi-area-know-subknow {
  font-size: 13px;
}

.poi-area-know-subknow a {
  color: #4a4a4a;
}

.inside-anchor {
  display: block;
  position: relative;
  top: -74px;
  visibility: hidden;
}

.poi {
  margin: 25px 0px;
  line-height: 1.3em;
}

.poi-block {
  padding: 15px 15px 20px 15px;
  background-color: white;
  display:flex;
  flex-direction: row;
}

.poi-block-information {
  font-size:30px;
  font-family:serif;
  font-style: italic;
  font-weight: bold;
  color: #b63f78;
  margin-right: 15px;
  padding-top: 7px;
}

.poi-block-description {
  margin-top: 4px;
  font-size: 14px;
  padding-right: 8px;
}

.poi-block-description-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.poi-block-description-short-more {
  color: #b63f78;
  cursor: pointer;
}

.poi-block-description-short-more:hover {
  text-decoration: underline;
}

.poi-medias {
  margin-top: 30px;
  display: grid;
  grid-template-columns: calc(50% - 25px) calc(50% - 25px);
  column-gap: 50px;
  row-gap: 30px;
}

.poi-download {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  color: #b63f78;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
}

.poi-download-buttons {
  display: flex;
  flex-direction: row;
}

.poi-download-app {
  border: 2px solid #b63f78;
  border-radius: 10px;
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 50px;
}

.poi-download-text {
  flex-grow: 1;
}

.poi-download img {
  width: 32px;
  height: 32px;
  margin: 2px 3px;
  filter: invert(34%) sepia(16%) saturate(4154%) hue-rotate(290deg) brightness(92%) contrast(86%);
}

.poi-download-app-text-begin {
  font-size:13px;
  font-weight: normal;
  span {
    white-space: nowrap;
    overflow: hidden;
  }
}

.poi-download a {
  color: #b63f78;
}

.poi-br {
  display: none;
}

@media only screen and (max-width: 767px) {
  .inside-anchor {
    top: 0px;
  }

  .poi-area {
    margin-left: -15px;
    margin-right: -15px;
  }

  .poi-download {
    display: block;
  }

  .poi-medias {
    display: block;
  }

  .poi-media {
    margin-bottom: 30px;
  }

  .poi-br {
    display: inline;
  }

  .poi-download-buttons {
    margin-top: 20px;
  }

  .poi-download-buttons div:first-child {
    margin-left: 0;
  }
}
`;

export default PoiWrapper;
