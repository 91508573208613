import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import PropTypes from 'prop-types';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import SingleProductCard from 'ToolboxComponents/commons/cards/single-product-card/single-product-card';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import ToolboxLightbox from 'ToolboxComponents/webapp/modals/lightbox/lightbox';
import { SingleProductSlideWrapper, CounterWrapper } from 'ToolboxComponents/commons/sliders/slider-single-product/slider-single-product.styles';

const SliderSingleProduct = props => {
  const { photos, height, title } = props;

  const theme = useContext(ThemeContext).state;
  const [lightboxVisible, setLightboxVisible] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState(0);

  //isMobile
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  // const images = product.map(product => product.image);

  return (
    <SingleProductSlideWrapper {...props} isMobile={isMobile}>
      <CounterWrapper {...props} theme={theme} isMobile={isMobile}>
        <div
          className="badge"
          onClick={() => {
            setLightboxVisible(true);
          }}
        >
          <div className="icon adn adn-camera"></div>
          <div className="pictures-number">
            {photos.length} photo{photos.length > 1 ? 's' : ''}
          </div>
        </div>
        {false && (
          <div
            className="badge"
            onClick={() => {
              setLightboxVisible(true);
            }}
          >
            <div className="icon adn adn-video"></div>
            <div className="pictures-number">
              {photos.length} video{photos.length > 1 ? 's' : ''}
            </div>
          </div>
        )}
      </CounterWrapper>
      <Slider
        key={'slider-single-product'}
        className={props.className}
        // style={props.style}
        arrowsBackground={'royalblue'}
        arrowsColor={'white'}
        dotsColorActive={'royalblue'}
        handleClick={() => setLightboxVisible(true)}
        getCurrentSlideIndex={flag => setSelectedSlide(flag)}
        desktop={{
          arrows: true,
          arrowsInside: true
        }}
      >
        {photos.map((photo, index) => (
          <SingleProductCard
            index={index}
            title={title}
            key={`slide-product--${index}`}
            photo={photo}
            isMobile={isMobile}
            height={height}
          />
        ))}
      </Slider>
      {lightboxVisible === true && (
        <ToolboxLightbox
          images={photos}
          selectedSlide={selectedSlide}
          setSelectedSlide={id => setSelectedSlide(id)}
          setLightboxVisible={flag => setLightboxVisible(flag)}
        />
      )}
    </SingleProductSlideWrapper>
  );
};

SliderSingleProduct.defaultProps = {
  className: '',
  height: {
    mobile: 250,
    desktop: 400,
  },
};

SliderSingleProduct.propTypes = {
  className: PropTypes.string, //slider single products extra classNames
  height: PropTypes.object, //slider height in px
};

export default SliderSingleProduct;
