import styled from 'styled-components';

const MediaWrapper = styled.div`
.poi-media:hover {
  cursor: pointer;
  color: #b63f78;
}

.poi-media a {
  color: #4A4A4A;
}

.poi-media-picture {
  width: 100%;
}

.poi-media-picture img{
  object-fit: cover;
  width: 100%;
  height: 160px;
}

.poi-media-title {
  margin-top: 7px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
}`;

export default MediaWrapper;

