import React from 'react';
import PropTypes from 'prop-types';
import { IconTextWrapper } from './icon-text.styles';

const IconText = props => {
  let link, target;
  if (props.aTag) {
    if (props.type === 'phone') {
      link = `tel:${props.text}`;
    }
    else if (props.type === 'mail') {
      link = `mailto:${props.text}`;
    } else {
      link = props.link;
      target = '_blank';
    }
  }
  return (
    <IconTextWrapper {...props}>
      {props.aTag ?
        <a target={target} href={link}><i className={props.icon.class}>{props.icon.text}</i>{props.text}</a>
        : <><i className={props.icon.class}>{props.icon.text}</i><span className='text' dangerouslySetInnerHTML={{__html: props.text}}></span></>
      }
    </IconTextWrapper>
  );
}

IconText.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  aTag: PropTypes.bool.isRequired,
  stick: PropTypes.bool.isRequired,
  type: PropTypes.string,
  link: PropTypes.string,
  textColor: PropTypes.string,
  spaceBottom: PropTypes.bool,
}

export default IconText;
