import React, { useContext, useState } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import getDevicePlatform from 'ToolboxUtils/web/get-device-platform';
import AppIos from 'ToolboxAssets/img/app-ios.svg';
import AppPlay from 'ToolboxAssets/img/app-play.svg';
import Text from 'ToolboxComponents/commons/texts/text/text';
import Poi from './poi/poi';
import PoisWrapper from './pois.style';
import PropTypes from 'prop-types';

const Pois = ({ pois }) => {
  const theme = useContext(ThemeContext).state;
  const color = useContext(ThemeContext).state.color;
  const [device] = useState(getDevicePlatform());

  return (
    <PoisWrapper
      theme={theme}
      className={`benefits page-gutters`}
      color={color}
    >
      <div className='poi-area'>
        <div className='poi-area-know'>
          <Text path='page.product.poiKnow' /> <br className='poi-br'/> <Text path='page.product.poiTell' />
        </div>
        <div className='poi-area-know-subknow'>
          <a href='#mobile-app'><Text path='page.product.poiDownload' /></a>
        </div>
        {pois.map((poi, index) => <Poi key={index} poi={poi}/>)}
        <a className='inside-anchor' name='mobile-app'></a>
        <div className='poi-download'>
          <div className='poi-download-text'>
            <Text path='page.product.poiText1' /> <br /><Text path='page.product.poiText2' /> <br /> <Text path='page.product.poiText3' />
          </div>
          <div className='poi-download-buttons'>
          {(device !== 'android') &&
          <a href={(device === "ios") ? 'itms-apps://itunes.com/apps/saint-%C3%A9tienne-balades/id1503521159' : 'https://apps.apple.com/fr/app/saint-%C3%A9tienne-balades/id1503521159'}>
          <div className='poi-download-app'>
            <img src={AppIos}/>
            <div className='poi-download-app-text'>
              <div className='poi-download-app-text-begin'><Text path='page.product.poiDownloadOn' /></div>
              <div className='poi-download-app-text-end'>App Store</div>
            </div>
          </div>
          </a>
          }
          {(device !== "ios") &&
          <a href={(device === 'android') ? 'market://details?id=fr.stetiennemetropole.balades' : 'https://play.google.com/store/apps/details?id=fr.stetiennemetropole.balades'}>
          <div className='poi-download-app'>
            <img src={AppPlay}/>
            <div className='poi-download-app-text'>
              <div className='poi-download-app-text-begin'><Text path='page.product.poiDownloadOn' /></div>
              <div className='poi-download-app-text-end'>Google Play</div>
            </div>
          </div>
          </a>
          }
          </div>
        </div>
      </div>
    </PoisWrapper>
  );
};

Pois.propTypes = {
  pois: PropTypes.array,
};

export default Pois;
