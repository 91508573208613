import React, { useContext, useState } from 'react';
import { ThemeContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import Media from './media/media';
import PoiWrapper from './poi.style';
import PropTypes from 'prop-types';

const Poi = ({ poi }) => {
  const theme = useContext(ThemeContext).state;
  const whiteLabel = useContext(WhiteLabelContext).state;
  const color = useContext(ThemeContext).state.color;

  const [isDescriptionShowed, setIsDescriptionShowed] = useState(false);

  return (
    <PoiWrapper
      theme={theme}
      className={`benefits page-gutters`}
      color={color}
    >
      <div className='poi'>
        <div className='poi-block'>
          <div className='poi-block-information'>
            i
          </div>
          <div className='poi-block-description'>
            <div className='poi-block-description-title'>
              {poi.title}
            </div>
            <div className='poi-block-description-short'>
              <span className='poi-block-description-short-text'>
                {poi.teaser}
              </span> &nbsp;
              <span className='poi-block-description-short-more' onClick={() => {setIsDescriptionShowed(!isDescriptionShowed)}}>
                {whiteLabel.textualContent.page.product.poiSeeMore}
              </span>
            </div>
            {isDescriptionShowed && <div className='poi-block-description-long'>
              <br />
              {poi.description.split("\n").map((item, idx) =>
                <span key={idx}>
                    {item}
                    <br/>
                </span>)
                }
            </div>
            }
          </div>
        </div>
        <div className='poi-medias'>
          {poi.medias && poi.medias.map((media, index) => <Media key={index} id={index} media={media}/>)}
        </div>
      </div>
    </PoiWrapper>
  );
};

Poi.propTypes = {
  pois: PropTypes.array,
};

export default Poi;
