import React, { useContext } from 'react';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { ThemeContext, WebappContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import PropTypes from 'prop-types';
import IconText from 'ToolboxComponents/commons/texts/icon-text/icon-text';
import Map from 'ToolboxComponents/commons/map/map';
import formatAddress from 'ToolboxUtils/web/formats/address';
import secoundsConverter from 'ToolboxUtils/web/formats/seconds-converter';
import { ContactMapWrapper } from './contact-map.styles';
import DirectionsLink from 'ToolboxComponents/commons/links/directions-link/directions-link';
import styled from 'styled-components';

const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .icon {
    font-size: 2rem;
    margin-right: 1rem;
  }

  .link {
    color: #2d2d2d;
    &:hover {
      text-decoration: none;
    }
  }
`;

const ContactMap = ({ product }) => {
  const theme = useContext(ThemeContext).state;
  const whiteLabel = useContext(WhiteLabelContext).state;
  const [analytics] = useContext(WebappContext).usePath('analytics');

  // const textualContent = state.whiteLabel.textualContent;
  // console.log("textualContent",textualContent)
  const address = formatAddress({
    data: product,
    options: { secondBreak: ',' },
  });

  return (
    <ContactMapWrapper theme={theme} className="contact-map">
      <h3>
        <Text path={`page.product.contactTitle`} />
      </h3>
      <div className="info-list">
        {product.phone && (
          <IconText
            text={product.phone}
            icon={{ class: 'icon adn adn-phone' }}
            type="phone"
            aTag={true}
            stick={false}
            onClick={() => {analytics.sendPhoneProductEvent(product)}}
          />
        )}
        {product.mail && (
          <IconText
            text={product.mail}
            icon={{ class: 'icon adn adn-email' }}
            type="mail"
            aTag={true}
            stick={false}
            onClick={() => {analytics.sendMailProductEvent(product)}}
          />
        )}
        {product.url && (
          <IconText
            text={whiteLabel.textualContent.page.product.seeWebsite}
            icon={{ class: 'icon adn adn-link' }}
            link={product.url}
            aTag={true}
            stick={false}
            onClick={() => {analytics.sendWebsiteProductEvent(product)}}
          />
        )}
        {product.messenger && (
          <IconText
            text="FB Messenger"
            icon={{ class: 'icon adn adn-message-outline' }}
            link={product.messenger}
            aTag={true}
            stick={false}
            onClick={() => {analytics.sendMessengerProductEvent(product)}}
          />
        )}
        {address && (
          <IconTextWrapper>
            <span className="icon adn adn-home" />
            <DirectionsLink className={`link`} product={product} onClick={() => {analytics.sendAddressProductEvent(product)}}>
              <div className="title"><span dangerouslySetInnerHTML={{__html:address}}></span></div>
            </DirectionsLink>
          </IconTextWrapper>
        )}
      </div>
      <Map
        itemLocations={[
          { latitude: product.latitude, longitude: product.longitude },
        ]}
        // userLocation={{
        //   latitude: product.latitude,
        //   longitude: product.longitude,
        // }}
        height="200px"
        selected={0}
      />
      {product.timeTravel && (
        <div className="time">
          {product.timeTravel.car && (
            <IconText
              text={secoundsConverter(product.timeTravel.car)}
              icon={{ class: 'icon adn adn-car' }}
              aTag={false}
              stick={true}
              size="1.5rem"
            />
          )}
          {product.timeTravel.walk && (
            <IconText
              text={secoundsConverter(product.timeTravel.walk)}
              icon={{ class: 'icon adn adn-walk' }}
              aTag={false}
              stick={true}
              size="1.5rem"
            />
          )}
          {product.timeTravel.bike && (
            <IconText
              text={secoundsConverter(product.timeTravel.bike)}
              icon={{ class: 'icon adn adn-bike' }}
              aTag={false}
              stick={true}
              size="1.5rem"
            />
          )}
        </div>
      )}
    </ContactMapWrapper>
  );
};

ContactMap.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ContactMap;
