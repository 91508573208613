export default {
  weekDays: {
    fr: [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ],
    en: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
  },
  weekDaysFrenchFormat: {
    fr: [
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
      "Dimanche",
    ],
    en: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
  },
  duration: {
    fr: "Durée moyenne de l'activité : {{duration}}min",
    en: "Average duration of the activity: {{duration}}min",
  },
  more: { fr: "...voir plus", en: "...see more" },
  citycard: { fr: "Citycard", en: "Citycard" },
  admission: {
    fr:
      "Entrées sur toutes les expos + bus/tram + avantages et réductions chez nos partenaires",
    en:
      "Admission to all the exhibitions + bus/tram + offers and deals at our partners",
  },
  photo: { fr: "photo", en: "photo" },
  video: { fr: "vidéo", en: "video" },
  gps: {
    fr: "Choisisez votre service de navigation",
    en: "Choose your navigation service",
  },
  list: { fr: "Liste des", en: "List of" },
  steps: { fr: "étapes", en: "steps" },
  unmissable: { fr: "incontournables", en: "unmissables" },
  allSteps: {
    fr: "Voir toutes les étapes du parcours",
    en: "See all the steps in the circuit",
  },
  noSchedules: {
    fr: "Aucune date pour les 15 prochains jours.",
    en: "No date in the next 15 days.",
  },
  closed: { fr: "Fermé", en: "Closed" },
  open: { fr: "Ouvert", en: "Open" },
  diary: { fr: "Agenda sur 15 jours", en: "Diary for 15 days" },
  maxPrice: { fr: "Jusqu'à {{maxPrice}} €", en: "Up to €{{maxPrice}}" },
  minPrice: { fr: "A partir de {{minPrice}} €", en: "From €{{minPrice}}" },
  between: {
    fr: "De {{minPrice}} € à {{maxPrice}} €",
    en: "From €{{minPrice}} to €{{maxPrice}}",
  },
  allWeek: { fr: "Ouvert toute la semaine", en: "Open all week" },
  benefits: { fr: "Les plus...", en: "The most..." },
  locals: { fr: "Des locaux passionnés", en: "Passionate locals" },
  whisper: { fr: "chuchotent leurs secrets", en: "whisper their secrets" },
  too: { fr: "vous aussi", en: "you too" },
  whisperPlace: {
    fr: "chuchotez un secret sur cette adresse...",
    en: "has a secret to whisper about this place...",
  },
  whisperBy: { fr: "Chuchoté par", en: "Whispered by" },
  secretCount: {
    fr: "Voir ses {{secretCount}} secrets",
    en: "See its {{secretCount}} secrets",
  },
  socials: { fr: "Sur les réseaux sociaux", en: "On social media" },
  travelers: { fr: "Ce qu'en disent les voyageurs", en: "What travellers say" },
  rates: { fr: "Tarifs", en: "Rates" },
  free: { fr: "Gratuit", en: "Free" },
  schedule: { fr: "Horaires", en: "Opening hours" },
  whisperGeolocation: {
    fr: "Veuillez activer la géolocalisation pour pouvoir chuchoter un secret",
    en: "Please turn on geolocation to whisper a secret",
  },
};

export const scheduleData = [
  {
    endTime: 1568757600000,
    startTime: 1568671200000,
  },
  {
    endTime: 1568844000000,
    startTime: 1568757600000,
  },
  {
    endTime: 1568930400000,
    startTime: 1568844000000,
  },
  {
    endTime: 1569016800000,
    startTime: 1568930400000,
  },
  {
    endTime: 1569103200000,
    startTime: 1569016800000,
  },
  {
    endTime: 1569189600000,
    startTime: 1569103200000,
  },
  {
    endTime: 1569276000000,
    startTime: 1569189600000,
  },
  {
    endTime: 1569362400000,
    startTime: 1569276000000,
  },
  {
    endTime: 1569448800000,
    startTime: 1569362400000,
  },
  {
    endTime: 1569535200000,
    startTime: 1569448800000,
  },
  {
    endTime: 1569621600000,
    startTime: 1569535200000,
  },
  {
    endTime: 1569708000000,
    startTime: 1569621600000,
  },
  {
    endTime: 1569794400000,
    startTime: 1569708000000,
  },
  {
    endTime: 1569880800000,
    startTime: 1569794400000,
  },
];
