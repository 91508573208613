export default function secoundsConverter(duration) {

  if (duration < 3600) {
    duration = Math.round(duration/60) + ' min';
  } else if (duration > 3600) {
    let hours = Math.floor(duration/3600);
    let min = Math.round((duration % 3600)/60);
    if(min < 10){ min = '0' + min; }
    duration = hours + 'h' + min;
  }

  return duration;
}
