import styled from 'styled-components';

export const IconTextWrapper = styled.p`
  color: #2D2D2D;
  display: flex;
  align-items: center;
  margin-bottom: ${props => { return props.noSpaceBottom ? '0' : '1rem' }};
  i {
    margin-right: ${props => { return props.stick ? '.2rem' : '1rem' }};
    font-size: ${props => { return props.size ? props.size : '2rem' }};
  }
  a {
    display: flex;
    align-items: center;
    color: #2D2D2D !important;
  }
  a:hover {
    text-decoration: none;
  }
  .text {
    color: ${props => { return props.textColor ? props.textColor : 'inherit' }};
  }
`;
