import React, { useState, useEffect, useRef, useContext } from 'react';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import { ThemeContext, WebappContext, AccountContext, WhiteLabelContext, LanguageContext } from 'ToolboxUtils/web/context/context';
import Title from 'ToolboxComponents/webapp/integrations/layout/menu/product/components/title';
import ProductMenu from 'ToolboxComponents/webapp/integrations/layout/menu/product/product';
import SliderSingleProduct from 'ToolboxComponents/commons/sliders/slider-single-product/slider-single-product';
import Info from './components/info/info';
import Benefits from './components/benefits/benefits';
import Pois from './components/pois/pois';
import Description from './components/description/description';
import ButtonAddSecret from 'ToolboxComponents/commons/buttons/button-add-secret/button-add-secret';
import Schedule from './components/schedule/schedule';
import ContactMap from 'ToolboxComponents/webapp/blocks/contact-map/contact-map';
import ProductWrapper, { FavoriteWrapper, ProductMainWrapper, ProductSidebarWrapper } from './product-view.style.js';
import Favorite from 'ToolboxComponents/commons/favorite/favorite';
import Price from 'ToolboxComponents/webapp/pages/product-view/components/price/price';
import SliderSecretProduct from 'ToolboxComponents/commons/sliders/slider-secret-product/slider-secret-product';
import ProductOwnerMenu from 'ToolboxComponents/webapp/integrations/layout/menu/product-owner/product-owner';
import ModalAddSecret from 'ToolboxComponents/webapp/modals/modal-secret/modal-secret';
import Text from 'ToolboxComponents/commons/texts/text/text';


const Product = props => {
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const [bannerHeight, setBannerHeight] = useContext(WebappContext).usePath(
    'layout.bannerHeight',
  );
  const [stateAccount] = useContext(AccountContext).usePath();
  const [account, setAccount] = useState(
    stateAccount !== undefined ? stateAccount : undefined,
  );
  useEffect(() => setAccount(stateAccount), [stateAccount]);
  const { product, addFavorite, deleteFavorite, isSecretSaved, errorOnSecretSave, setSecretSaved, times } = props;
  const [isMobile, setIsMobile] = useState(null);
  const [isSecretModal, setIsSecretModal] = useState(false);

  const toggleFavoriteActive = (active, productId) => {
    if (active === true) {
      deleteFavorite(productId);
    } else {
      addFavorite(productId);
    }
  };
  

  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);
  const productRef = useRef();
  const bannerRef = useRef();
  useEffect(() => {
    setBannerHeight(bannerRef.current.clientHeight);
    return () => setBannerHeight(null);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!isSecretModal) {
      setSecretSaved(false);
    }
  }, [isSecretModal]);
  
  const isBiennale = product.idEvenementGenerique === 3834 || product.isBiennaleEvent ===  true;
  const isRugby = product.extra && product.extra.isRugby;
  const isJOP = product.idEvenementGenerique === 6500;

  return (
    <ProductWrapper ref={productRef} theme={theme}>
      <ProductMenu product={product} />
      <div className={`header-slider  ${isBiennale ? 'macaron-biennale' : ''} ${isJOP ? 'macaron-event' : ''}`} ref={bannerRef}>
        <SliderSingleProduct
          photos={product.genericPhotos}
          title={product.title}
          height={{ mobile: 250, desktop: 400 }}
        />
      </div>
      {whiteLabel.config.hasAccount !== false &&
      <FavoriteWrapper className='favorite'>
        <Favorite
          key='favorite'
          onClick={active => toggleFavoriteActive(active, product.id)}
          product={product}
          fromPage='product'
        />
      </FavoriteWrapper>
      }
      <ProductMainWrapper productRef={productRef}>
        <Title product={product} />
        {isMobile === true && <Info product={product} times={times} isEvent={product.isEvent}/>}
        {product.benefits !== null && <Benefits benefits={product.benefits} />}
        {product.description !== null && (
          <Description description={product.description} teaser={product.teaser} showBothDescription={whiteLabel.config.showBothDescription}/>
        )}
        {product.pois !== null && language === 'fr' && (
          <Pois pois={product.pois} />
        )}
        {product.secrets !== null && (
          <div className="secret-slider page-gutters">
            <SliderSecretProduct secrets={product.secrets} productView={true} />
          </div>
        )}
        {account !== undefined &&  whiteLabel.id !== 10 && whiteLabel.id !== 24  && language === 'fr' && (
          <ButtonAddSecret
            handleClick={() => setIsSecretModal(true)}
            account={account}
          />
        )}
        {product.facebook || product.instagram
          ? <div className='social-medias'>
              {product.facebook &&
                <a href={product.facebook} target='_blank' className='social-media'>
                  <img src='https://api.agitateurs.com/upload/a97848d0-2d78-11eb-974d-5945c99ae888.png' />
                  Facebook
                </a>
              }
              {product.instagram &&
                <a href={product.instagram} target='_blank' className='social-media'>
                  <img src='https://api.agitateurs.com/upload/a13b5e00-2d78-11eb-974d-5945c99ae888.png' />
                  Instagram
                </a>
              }
            </div>
          : null
        }
        {product.schedule !== null && !(whiteLabel.config.isSelectedProductScheduleHidden && product.isSelectedProduct) && (
          <Schedule schedule={product.scheduleTable} isEvent={product.isEvent}></Schedule>
        )}
        {product.prices !== null && <Price prices={product.prices}></Price>}
        {product.doc &&
           <a className="download" href={product.doc} download> <i className="icon adn adn-pdf" /> <span> <Text path='page.product.download' /></span></a>
        }
        {isMobile === true && <><ContactMap product={product} />  {product.isQualiteTourisme &&<div className='logo-qualite-tourisme'></div>} </>}
      </ProductMainWrapper>
      {isMobile === false && (
        <ProductSidebarWrapper theme={theme}>
          <ProductOwnerMenu product={product} />
          <Info product={product} times={times} isEvent={product.isEvent}/>
          <ContactMap product={product} />
          {product.isQualiteTourisme && <div className='logo-qualite-tourisme'></div>}
        </ProductSidebarWrapper>
      )}
      {isSecretModal === true && (
        <ModalAddSecret
          product={product}
          onSubmit={payload => props.createSecret(payload.secret)}
          onClose={() => setIsSecretModal(false)}
          isSecretSaved={isSecretSaved}
          errorOnSecretSave={errorOnSecretSave}
        />
      )}
    </ProductWrapper>
  );
};

export default Product;
