import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import DescriptionWrapper from './description.style';
import PropTypes from 'prop-types';

const Description = ({ description, teaser, showBothDescription }) => {
  const theme = useContext(ThemeContext).state;
  const [fullDescription, setFullDescription] = useState(false);
  useEffect(() => {
    setTimeout(function(){
      const blockDescription = document.getElementById('description');
      if (blockDescription) {
        if (blockDescription.offsetHeight > 90) {
          blockDescription.classList.add('hide-bottom');
        } else {
          document.getElementsByClassName('read-more')[0].style.display = 'none';
        }
      }
    }, 100);
  }, []);

  return (
    <DescriptionWrapper
      className={`description page-gutters`}
      fullDescription={fullDescription}
      theme={theme}
    >
      <h2 className="title">
        <Text path={`page.product.shortDescriptionTitle`} />
      </h2>
      {showBothDescription && <div className="contentTeaser">{teaser.split(/\n/).map((item, idx) =>
                      <span key={idx}>
                          {item}
                          <br/>
                      </span>)}
      </div>}
      <div id='description' className="content">{description.split(/\n/).map((item, idx) =>
                      <span key={idx}>
                          {item}
                          <br/>
                      </span>)}
      </div>
      <div
        className="read-more"
        onClick={() => setFullDescription(!fullDescription)}
      >
        <Text path={`page.product.readMore`} />
      </div>
    </DescriptionWrapper>
  );
};

Description.propTypes = {
  description: PropTypes.string,
};

export default Description;
