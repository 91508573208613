import React, { useEffect, useState, useContext } from 'react';
import ProductOwnerWrapper from 'ToolboxComponents/webapp/integrations/layout/menu/product-owner/product-owner.style.js';
import { ThemeContext, WebappContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import isMobileView, {
  isMobileBreakpoint,
} from 'ToolboxUtils/web/is-mobile-view';
import DirectionsLink from 'ToolboxComponents/commons/links/directions-link/directions-link';
import getContrastColor from 'ToolboxUtils/web/get-contrast-color';

const ProductOwner = props => {
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();
  const [analytics] = useContext(WebappContext).usePath('analytics');

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  // const {
  //   product: { phone = null, booking = null, url = null },
  // } = props;

  const contrastColor = getContrastColor(theme.color.tertiaryColor ?? theme.color.primaryColor, theme.color.secondaryColor, '#FFFFFF')
  
  return (
    <ProductOwnerWrapper
      {...props}
      isMobileBreakpoint={isMobileBreakpoint}
      theme={theme}
      contrastColor={contrastColor}
    >
      <div className="options">
        <DirectionsLink className={`option`} product={props.product} onClick={() => {analytics.sendItineraryProductEvent(props.product)}}>
          <span className="icon adn adn-directions" />
          <div className="title">{whiteLabel.textualContent.page.product.directions}</div>
        </DirectionsLink>
        {props.product !== undefined && props.product.phone !== null && (
          <a href={`tel:${props.product.phone}`} className="option" onClick={() => {analytics.sendPhoneProductEvent(props.product)}}>
            <span className="icon adn adn-phone" />
            <div className="title">{whiteLabel.textualContent.page.product.call}</div>
          </a>
        )}
        {props.product !== undefined && props.product.url !== null && (
          <a
            href={props.product.url}
            target="_blank"
            rel="noopener noreferrer"
            className="option"
            onClick={() => {analytics.sendWebsiteProductEvent(props.product)}}
          >
            <span className="icon adn adn-web" />
            <div className="title">{whiteLabel.textualContent.page.product.web}</div>
          </a>
        )}
        {props.product !== undefined && props.product.booking !== null && (
          <a
            href={props.product.booking}
            target="_blank"
            rel="noopener noreferrer"
            className="option highlight"
            onClick={() => {analytics.sendBookingProductEvent(props.product)}}
          >
            <span className="icon adn adn-cart" />
            <div className="title">{whiteLabel.textualContent.page.product.book}</div>
          </a>
        )}
      </div>
    </ProductOwnerWrapper>
  );
};

export default ProductOwner;
