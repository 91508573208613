import styled from "styled-components";

export const SingleProductSlideWrapper = styled.div`
  height: ${props =>
    props.isMobile === true ? props.height.mobile : props.height.desktop}px;
`;

export const CounterWrapper = styled.div`
  position: absolute;
  top: ${props => (props.isMobile === true ? "70px" : "")};
  bottom: ${props => (props.isMobile === false ? "20px" : "")};
  left: 10px;
  z-index: 2;
  display: flex;

  .badge {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    box-shadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    margin-right: 10px;
  }

  .icon {
    font-size: 27px;
    line-height: 0.6;
    margin-top: 13px;
    margin-bottom: 5px;
  }

  .pictures-number {
    font-size: ${props => `${9*props.theme.font.primaryFont.scaling/100}px`};
    line-height: 1;
  }
`;
