import React, { useContext } from 'react';
import Text from 'ToolboxComponents/commons/texts/text/text';
import ButtonAddSecretWrapper from './button-add-secret.style';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import PropTypes from 'prop-types';

const ButtonAddSecret = props => {
  const theme = useContext(ThemeContext).state;
  const { account, handleClick } = props;
  const name = account.firstName;

  return (
    <ButtonAddSecretWrapper
      className={`secret-add-button`}
      theme={theme}
      {...props}
    >
      <div
        className="container-button-add-secret"
        onClick={() => handleClick()}
      >
        <div className="icon adn adn-message-outline"></div>
        <div className="text">
          <div className="text-name">
            <Text path={`page.product.whisperNewSecretName`} data={{ name }} />
          </div>
          <div className="text-info">
            <Text path={`page.product.whisperNewSecretText`} />
          </div>
        </div>
      </div>
    </ButtonAddSecretWrapper>
  );
};

ButtonAddSecret.propTypes = {
  account: PropTypes.object,
};
export default ButtonAddSecret;
