import styled from 'styled-components';

export const ProductMenuDesktopWrapper = styled.nav`
  display: flex;
  align-items: center;
  height: 80px;
  width: 100vw;
  background: ${props => props.theme.color.tertiaryColor};
  position: fixed;
  top: 0;
  left: 100vw;
  transition: left 0.3s;

  &.active {
    left: 0;
  }

  .desktop-back-arrow {
    background: ${props => props.theme.color.primaryColor};
    color: white;
    height: 100%;
    width: 80px;
    margin-right: 50px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    .icon {
      font-size: 40px;
      line-height: 18px;
    }
  }

  .desktop-title {
    width: calc(100% - 400px);
  }
  .product-title,
  .product-location {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .product-title {
    font-size: 24px;
  }
  .product-location {
    font-size: 15px;
  }
`;

export const ProductMenuMobileWrapper = styled.nav`
  background: ${props => props.theme.color.primaryColor};
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  text-transform: uppercase;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  animation: menu-down 0.3s;

  a {
    color: white;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    height: 35px;
    width: 35px;
    border: 2px solid ${props => props.theme.color.secondaryColor};
    border-radius: 50%;
  }

  .back-arrow,
  .option {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 60px;
  }

  .back-arrow {
    background: ${props => props.theme.color.primaryColor};
    padding-top: 5px;
    height: 60px;
    & .icon {
      border-color: white;
    }
  }

  .options {
    display: flex;
    justify-content: space-evenly;
    flex-grow: 1;
    background: ${props => props.theme.color.tertiaryColor};
    height: 60px;
    padding-top: 5px;
  }

  .option {
    color: ${props => props.theme.color.secondaryColor};
    height: 55px;

    &.highlight {
      color: ${props => props.theme.color.primaryColor};
      & .icon {
        background: ${props => props.theme.color.primaryColor};
        border-color: ${props => props.theme.color.primaryColor};
        color: white;
      }
    }
  }

  @keyframes menu-down {
    from {
      top: -72px;
    }
    to {
      top: 0px;
    }
  }
`;
