import React, { useEffect, useState, useContext } from 'react';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import {
  ProductMenuMobileWrapper,
  ProductMenuDesktopWrapper,
} from './product.style';
import PropTypes from 'prop-types';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import ProductOwnerMenu from 'ToolboxComponents/webapp/integrations/layout/menu/product-owner/product-owner';
import { useHistory } from 'react-router-dom';

const ProductMenu = props => {
  const history = useHistory();
  const { product } = props;
  const theme = useContext(ThemeContext).state;

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);
  return (
    <>
      {isMobile === true && (
        <ProductMenuMobileWrapper theme={theme}>
          <div className="back-arrow" onClick={() => history.goBack()}>
            <span className="icon adn adn-arrow-left" />
            <div className="title">
              <Text path={`generic.navigation.goBack`} />
            </div>
          </div>
          <ProductOwnerMenu product={product} />
        </ProductMenuMobileWrapper>
      )}
      {isMobile === false && (
        <ProductMenuDesktopWrapper theme={theme}>
          <div className="desktop-back-arrow">
            <div className="adn adn-arrow-left icon"></div>
            <div className="message">
              <Text path={`generic.navigation.goBack`} />
            </div>
          </div>
          <div className="desktop-title">
            <div className="product-title">{product.title}</div>
            <div className="product-location">{product.city}</div>
          </div>
        </ProductMenuDesktopWrapper>
      )}
    </>
  );
};

ProductMenu.propTypes = {
  product: PropTypes.object, //the current product page's information
  geolocation: PropTypes.object, //user's current position
  account: PropTypes.object, //user's transportation
};

export default ProductMenu;
