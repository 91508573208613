import styled from 'styled-components';

const ButtonAddSecretWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;

  .container-button-add-secret {
    cursor: pointer;
    background: ${props => props.theme.color.primaryColor};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;
    border-radius: 25px;
    color: white;
    line-height: 1;
  }
  .icon {
    font-size: 40px;
    margin-right: 10px;
    line-height: 0;
  }
  .text {
    font-size: ${props => `${15*props.theme.font.primaryFont.scaling/100}px`};
  }

  .text-info {
    color: white !important;
  }
`;

export default ButtonAddSecretWrapper;
