import React, { useContext } from 'react';
import { ThemeContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import formatPrice from 'ToolboxUtils/web/formats/format-price';
import PriceWrapper from 'ToolboxComponents/webapp/pages/product-view/components/price/price.style';

const Price = props => {
  const whiteLabel = useContext(WhiteLabelContext).state;
  const theme = useContext(ThemeContext).state;
  const { prices } = props;

  return (
    <PriceWrapper theme={theme} className={`page-gutters`}>
      <div className="title">
        <Text path={`page.product.pricesTitle`} />
      </div>
      <div className="prices">
        {prices.map((price, index) => {
          return (
            <div key={`price-${index}`} className={`price-row`}>
              <div className="name">{price.category}</div>
              {(price.minimum !== undefined ||
                price.maximum !== undefined) &&
                (price.minimum !== null || price.maximum !== null) && (
                  <div className="price">
                    {price.minimum === price.maximum ||
                    (price.maximum === null || price.maximum === undefined) || (price.minimum === null || price.minimum === undefined) ? (
                      <>
                        {' '}
                        {formatPrice(price.minimum || price.maximum)} {'\u20AC'}{' '}
                      </>
                    ) : (
                      <>
                        {' '}
                        {whiteLabel.textualContent.page.product.priceFrom} {formatPrice(price.minimum)}
                        {'\u20AC'} {whiteLabel.textualContent.page.product.priceTo} {formatPrice(price.maximum)}
                        {'\u20AC'}
                      </>
                    )}
                  </div>
                )}
              <div className="precision">{price.precision}</div>
            </div>
          );
        })}
      </div>
    </PriceWrapper>
  );
};

export default Price;
