import styled from 'styled-components';

const TitleWrapper = styled.div`
  overflow: hidden;
  position: -webkit-sticky;
  position: ${props => (props.isMobile === true ? 'static' : 'sticky')};
  top: 0;
  height: 80px;
  margin-top: 40px;
  transition: all 0.3s;

  &.active {
    z-index: 2;
    background: ${props => props.theme.color.tertiaryColor};

    .product-title {
      margin-left: 0;
      background: ${props => props.theme.color.tertiaryColor};
    }
  }

  .product-title {
    height: ${props => (props.isMobile === true ? '87px' : '80px')};
    width: calc(100%-80px);
    background: white;
    display: flex;
    margin-left: -80px;
    transition: all 0.3s;

    .title-wrapper {
      padding: 0 ${props => (props.isMobile === true ? '40px' : '0 0 60px')};
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    .title {
      display: block;
      margin: 0;
      font-size: ${props =>
        `${(30 * props.theme.font.primaryFont.scaling) / 100}px`};
      line-height: 1;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding-bottom: 5px;
    }

    .back-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 80px;
      font-size: 40px;
      color: white;
      background: ${props => props.theme.color.primaryColor};
      position: relative;
      cursor: pointer;
    }
  }
`;

export default TitleWrapper;
