import styled from "styled-components";

const CardWrapper = styled.div`
  .image {
    height: ${props =>
      props.height === undefined ? '100%' :
      (props.isMobile === true ? props.height.mobile + 'px' : props.height.desktop + 'px')
    }
    width: 100%;
    object-fit: cover;
  }
`;

export default CardWrapper;
