import styled from 'styled-components';

const InfoWrapper = styled.div`
  .info {
    display: flex;
    align-items: center;
  }

  .icon {
    font-size: 22px;
    line-height: 1;
    margin-right: 0.2rem;
  }

  .is-on {
    color: green;
  }

  .is-ok {
    color: #1DA1F2;
  }

  .is-off {
    color: red;
  }
`;

export default InfoWrapper;
