import scheduleText from 'ToolboxUtils/web/formats/schedule/schedule-data';
import formatHour from 'ToolboxUtils/web/formats/format-hour';
import formatShortDate from 'ToolboxUtils/web/formats/format-short-date';
import formatWeekDay from 'ToolboxUtils/web/formats/format-week-day';

export default function getScheduleTable(schedule, language, isEvent) {
  const MAX_DAYS = isEvent ? 15 : 7;
  const today = new Date();
  const week = [];

  for (let i = 0; i < MAX_DAYS; i++) {
    const startDate = new Date();
    startDate.setDate(today.getDate() + i);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date();
    endDate.setDate(today.getDate() + i + 1);
    endDate.setHours(0, 0, 0, 0);

    const midDate = new Date(startDate);
    midDate.setHours(12, 0, 0, 0);

    let endTime = [];
    let startTime = [];
    let endTimeMs = [];
    let startTimeMs = [];
    let scheduleStartDate
    schedule.forEach((value) => {
       scheduleStartDate = new Date(value.startTime);
      const scheduleEndDate = new Date(value.endTime);
      if (scheduleEndDate > startDate && scheduleStartDate < endDate) {
        // période englobante (ex : du 1er janvier 2020 au 31 décembre 2020)
        if (scheduleStartDate < startDate && scheduleEndDate > endDate) {
          endTime.push('00:00');
          startTime.push('00:00');
          endTimeMs.push(value.startTime);
          startTimeMs.push(value.endTime);
        } else {
          if (endTime.length > 0) {
            for (let endTime1 of endTime) {
              if (endTime1 !== formatHour({date: scheduleEndDate, language})) {
                endTime.push(formatHour({date: scheduleEndDate, language}));
                endTimeMs.push(value.endTime);
              }
            }
          } else {
            endTime.push(formatHour({date: scheduleEndDate, language}));
            endTimeMs.push(value.endTime);
          }
          if (startTime.length > 0) {
            for (let startTime1 of startTime) {
              if (startTime1 !== formatHour({date: scheduleStartDate, language})) {
                startTime.push(formatHour({date: scheduleStartDate, language}));
                startTimeMs.push(value.startTime);
              }
            }
          } else {
            startTime.push(formatHour({date: scheduleStartDate, language}));
            startTimeMs.push(value.startTime);
          }
        }
      }
    });

    week[isEvent ? formatShortDate({date: midDate, language}): formatWeekDay({date: midDate, language})] = { endTime, startTime, endTimeMs, startTimeMs };
  }

  return week;
}
