import React from 'react';
import PropTypes from 'prop-types';
import CardWrapper from './single-product-card.style';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';

const ProductCard = props => {
  return (
    <CardWrapper {...props} onClick={() => props.onClick()}>
      <ImageResize src={props.photo} className="image" width={`large`} alt={props.title + ' - photo ' + (props.index + 1)}/>
    </CardWrapper>
  );
};

ProductCard.defaultProps = {
  onClick: () => {}, //on card click
};

ProductCard.propTypes = {
  product: PropTypes.object, //all product information
};

export default ProductCard;
