function luminance({r, g, b}) {
  var a = [r, g, b].map(function(v) {
    v /= 255;
    return v <= 0.03928 ?
      v / 12.92 :
      Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function contrast(rgb1, rgb2) {
  var lum1 = luminance(rgb1);
  var lum2 = luminance(rgb2);
  var brightest = Math.max(lum1, lum2);
  var darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) /
    (darkest + 0.05);
}

function getRgb(color) {
  const colorStr = (color.charAt(0) === '#') ? color.substring(1, 7) : color;
  return {
    r: parseInt(colorStr.substring(0, 2), 16),
    g: parseInt(colorStr.substring(2, 4), 16),
    b: parseInt(colorStr.substring(4, 6), 16)
  }
}

export default function getContrastColor(bgColorStr, color1Str, color2Str) {
  const bgColor = getRgb(bgColorStr);
  const color1 = getRgb(color1Str);
  const color2 = getRgb(color2Str);
  const contrast1 = contrast(bgColor, color1);
  const contrast2 = contrast(bgColor, color2);
  
  return (contrast1 > contrast2 ? color1Str : color2Str);
}