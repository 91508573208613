import React from 'react';
import ModalMediaWrapper from './modal-media.style';


const ModalMedia = ({ media, id, setIsModalOpen }) => {
  return (
    <ModalMediaWrapper>
      <div className="modal modal-full modal-media" id={"ModalMedia" + id} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header pt-5 pb-5">
              <div className="container">
                <h4 className="modal-title float-left">{media.title}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Fermer" onClick={() => setIsModalOpen(false) }>
                  <i className="icon adn adn-close"></i>
                </button>
              </div>
            </div>
            <div className="modal-body">
                <iframe src={media.url} />
            </div>
          </div>
        </div>
      </div>
    </ModalMediaWrapper>
  );
}

export default ModalMedia;
