import React, { useEffect, useState, useContext } from 'react';
import { WhiteLabelContext , ThemeContext, LanguageContext} from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import ScheduleWrapper from './schedule.style';
import PropTypes from 'prop-types';
import { formatSchedule } from 'ToolboxUtils/web/formats/schedule/schedule';
import scheduleText from 'ToolboxUtils/web/formats/schedule/schedule-data';
import formatShortDate from 'ToolboxUtils/web/formats/format-short-date';
import getWeekDays from 'ToolboxUtils/general/get-week-days';

const getEventDates = () => {
  const dates = [];
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  for (let i = 0; i < 15 ; i++) {
    const startDate = new Date();
    startDate.setDate(today.getDate() + i);
    dates.push(formatShortDate(startDate));
  }
  return dates;
}

const generateTimeTable = (schedule, days, isEvent, language) => {
  return (
    <>
      {days.map((day, index) => (
        <div key={`schedule-${index}`} className="schedule-row">
          <div className="name">{day}</div>
          <div className="hours">
            {(!schedule[day]) || (schedule[day].endTime.length === 0 &&
            schedule[day].startTime.length === 0) ? (
              scheduleText.closed[language]
            ) : schedule[day].endTime.includes('00:00') &&
              schedule[day].startTime.includes('00:00') ? (
              scheduleText.open[language]
            ) : schedule[day].endTime.length > 1 ? (
              <>
                {schedule[day].startTime[0]}&nbsp;-&nbsp;
                {schedule[day].endTime[0]}&nbsp;
                <strong>&nbsp;|&nbsp;&nbsp;</strong>
                {schedule[day].startTime[1]}&nbsp;-&nbsp;
                {schedule[day].endTime[1]}
              </>
            ) : schedule[day].startTime[0] === schedule[day].endTime[0] ? (
              schedule[day].startTime[0]
            ) : (
              `${schedule[day].startTime[0]} - ${schedule[day].endTime[0]}`
            )}
          </div>
        </div>
      ))}
    </>
  );
};

const Schedule = ({ schedule, isEvent }) => {
  const [scheduleResult, setScheduleResult] = useState();
  const theme = useContext(ThemeContext).state;
  const [language] = useContext(LanguageContext).usePath();
  const [days] = useContext(WhiteLabelContext).usePath(
    'textualContent.page.product.weekDays',
  );
  //const daysArray = isEvent ? getEventDates() : getWeekDays();
  const daysArray = getWeekDays(language);

  useEffect(() => {
    //setScheduleResult(formatSchedule(schedule, language, isEvent));
    setScheduleResult(formatSchedule(schedule, language, false));
  }, [schedule]);

  return (
    <ScheduleWrapper theme={theme} className={`schedule page-gutters`}>
      <h3 className="title">
        <Text path={`page.product.scheduleTitle`} />
      </h3>
      <div className="days">
        {scheduleResult !== undefined &&
          days !== undefined &&
          //generateTimeTable(scheduleResult, daysArray, isEvent, language)}
          generateTimeTable(scheduleResult, daysArray, false, language)}
      </div>
    </ScheduleWrapper>
  );
};

Schedule.propTypes = {
  schedule: PropTypes.array,
};

export default Schedule;
