import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

export const ContactMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    // text-align: center;
    margin-left: 40px;
    font-family: ${props => props.theme.font.secondaryFont.name};
    // text-transform: uppercase;
    // font-size: ${props =>
      `${(20 * props.theme.font.secondaryFont.scaling) / 100}px`};
    font-size: 25px !important;
    font-weight: ${props =>
      parseInt(props.theme.font.secondaryFont.weight) + 300};
  }
  .info-list {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .time {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    p {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

@media (min-width: ${isMobileBreakpoint}px) {
  h2 {
  text-align: center;
  margin-left: 0;
}
}
`;
