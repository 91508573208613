import React, { useContext } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import AdvantagesWrapper from './benefits.style';
import PropTypes from 'prop-types';

const Advantages = ({ benefits }) => {
  const theme = useContext(ThemeContext).state;
  const color = useContext(ThemeContext).state.color;

  return (
    <AdvantagesWrapper
      theme={theme}
      className={`benefits page-gutters`}
      color={color}
    >
      <div className="title">
        <Text path={`page.product.advantagesTitle`} />
      </div>
      <ul className="list">
        {benefits.map((benefit, index) => (
          <li key={index}>{benefit}</li>
        ))}
      </ul>
    </AdvantagesWrapper>
  );
};

Advantages.propTypes = {
  benefits: PropTypes.array,
};

export default Advantages;
