import React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const ToolboxLightbox = props => {
  return (
    <Lightbox
      mainSrc={props.images[props.selectedSlide]}
      nextSrc={props.images[props.selectedSlide + 1]}
      prevSrc={props.images[props.selectedSlide - 1]}
      onCloseRequest={() => props.setLightboxVisible(false)}
      onMovePrevRequest={() => props.setSelectedSlide(props.selectedSlide - 1)}
      onMoveNextRequest={() => props.setSelectedSlide(props.selectedSlide + 1)}
    />
  );
};

export default ToolboxLightbox;
