import getScheduleTable from 'ToolboxUtils/web/formats/schedule/get-schedule-table';

export function formatSchedule(input, language, isEvent) {
  const scheduleTable = [];
  const schedule = getScheduleTable(input || [], language, isEvent);
  for (const key in schedule) {
    const endTime = schedule[key].endTime ? schedule[key].endTime : [];
    const startTime = schedule[key].startTime ? schedule[key].startTime : [];
    scheduleTable[key] = { endTime, startTime };
  }
  return scheduleTable;
}
