import React, { useContext, useState } from 'react';
import ModalMedia from './modal-media/modal-media';
import MediaWrapper from './media.style';


const Media = ({ media, id }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <MediaWrapper>
      <div className = "poi-media"  data-toggle="modal" data-target={"#ModalMedia" + id} onClick={() => {setIsModalOpen(!isModalOpen)}}>
        <div className = "poi-media-picture">
          <img src={media.picture} />
        </div>
        <div className = "poi-media-title">
          {media.title}
        </div>
          {isModalOpen && <ModalMedia id={id} media={media} setIsModalOpen={setIsModalOpen}/>}
      </div>
    </MediaWrapper>
  )
}

export default Media;