import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import Text from 'ToolboxComponents/commons/texts/text/text';
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import SecretProductCard from 'ToolboxComponents/commons/cards/secret-product-card/secret-product-card';
import SecretHomeCard from 'ToolboxComponents/commons/cards/secret-home-card/secret-home-card';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import SecretContainer from './slider-secret-product.style';

const SliderSecretProduct = props => {
  const theme = useContext(ThemeContext).state;
  const { tertiaryColor } = useContext(ThemeContext).state.color;

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);
   

  const [sliderIndex, setSliderIndex] = useState(0);
  const [images, setImages] = useState([]);

  const { secrets } = props;
  useEffect(() => {
    setImages(secrets.map(secret => secret.photo));
  }, [secrets]);

  return (
    <>
      <SecretContainer
        isMobile={isMobile}
        tertiaryColor={tertiaryColor}
        className="slider-secret-product"
        homeProductCards={props.homeProductCards}
        theme={theme}
      >
        {!props.hasNoTitle && <div className="secret-slider-title">
          <Text path={`page.homepage.secretSliderTitle`} />
        </div>
        }
        {/* <div className="secret-slider-number"> */}
        {/*   <Text */}
        {/*     path={`page.homepage.secretSliderSubtitle`} */}
        {/*     data={{ */}
        {/*       secretsCount: secrets.length, */}
        {/*       secretPlural: secrets.length > 1 ? 's' : '', */}
        {/*       localPlural: secrets.length > 1 ? 'ux' : 'l', */}
        {/*     }} */}
        {/*   /> */}
        {/* </div> */}
        <Slider
          mobile={{
            slidesPerView: 1,
            dots: true,
            spaceBetween: 10,
          }}
          desktop={{
            arrows: true,
            spaceBetween: 10,
            centerInsufficientSlides: true,
          }}
          getCurrentSlideIndex={flag => setSliderIndex(flag)}
          className={`slider-secret-product-slider`}
        >
          {secrets.map((secret, index) => (
            <React.Fragment key={secret.id}>
              <SecretProductCard
                key={`secret-product-card--${index}`}
                secret={secret}
                roundedCorners={props.homeProductCards === undefined}
                showSecretImage={props.homeProductCards === undefined}
                productView={props.productView}
                onClick={props.onClick}
                isWidget={props.isWidget}
                getAccountUrl={props.getAccountUrl}
              />
              {props.homeProductCards !== undefined && (
                <SecretHomeCard secret={secret} />
              )}
            </React.Fragment>
          ))}
        </Slider>
      </SecretContainer>
    </>
  );
};
export default SliderSecretProduct;
